import Home from "./pages/homepage";
import Layout from "./components/Layout/Layout";
import { Route, Routes } from "react-router-dom";
import Privacy from "pages/privacy";
import Terms from "pages/terms";
import About from "pages/about";
import Contact from "pages/contact";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
      </Route>
    </Routes>
  );
}

export default App;
