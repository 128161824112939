export default function ContentTwo() {
  return (
    <section className="promo-content-block fix section-padding section-bg">
      <div className="container">
        <div className="align-items-center">
          <div className="col-xl-12 col-12 mt-5 mt-xl-0">
            <div className="legal-contents">
              <div className="section-title mb-4">
                <h6 className="pt-2 mb">Terms and conditions</h6>
                <p className="pt-2 mb">
                  Welcome to PlotPilot, the AI-powered interactive stories app
                  developed by PlotPilot Ltd. Before you start using our app, we
                  kindly ask you to carefully read and fully understand the
                  following terms and conditions. By downloading, accessing, or
                  using PlotPilot, you agree to be bound by these terms. If you
                  do not agree with any part of these terms, please do not use
                  the app.
                </p>
                <h6 className="pt-2 mb">1: Acceptance of Terms</h6>
                <p className="pt-2 mb">
                  By accessing PlotPilot, you acknowledge that you have read,
                  understood, and agreed to these terms and conditions. You
                  further affirm that you are at least 13 years old or have
                  legal consent from a parent or guardian.
                </p>
                <h6 className="pt-2 mb">2: License</h6>
                <p className="pt-2 mb">
                  PlotPilot grants you a limited, non-exclusive,
                  non-transferable, and revocable license to access and use the
                  app for personal, non-commercial purposes only. You agree not
                  to copy, modify, distribute, sell, lease, sublicense, reverse
                  engineer, or decompile any part of the app.
                </p>
                <h6 className="pt-2 mb">3: AI-Powered Narration</h6>
                <p className="pt-2 mb">
                  PlotPilot employs third-party tools to generate and narrate
                  stories based on user prompts. While the content is primarily
                  crafted for individual consumption, PlotPilot Ltd. permits
                  users to disseminate and raise awareness about the app under
                  the subsequent terms:
                </p>
                <p className="pt-2 mb">
                  Personal Sharing: Users are allowed to share the AI-derived
                  content on their personal social media channels or in private
                  conversations for non-commercial reasons. This encompasses the
                  distribution of excerpts, quotations, or links that
                  demonstrate PlotPilot's features.
                </p>
                <p className="pt-2 mb">
                  Acknowledgment: Users, when circulating AI-produced content,
                  are obliged to give due recognition to PlotPilot Ltd. This
                  should include phrasings such as "Generated by PlotPilot" or
                  equivalent acknowledgments, accompanied by pertinent app or
                  website links.
                </p>
                <p className="pt-2 mb">
                  Non-Commercial Clause: The AI-concocted content is not to be
                  employed for any business-oriented intentions. This covers,
                  but isn't restricted to, marketing campaigns, promotional
                  activities, or sales of goods and services.
                </p>
                <p className="pt-2 mb">
                  We may also collect user-generated content (e.g., photos,
                  screenshots, comments, and other materials) that you create on
                  the Service. Your photos can be taken by other users on our
                  services, and if they use the capturing function provided by
                  us, those photos can be stored and used for our services and
                  third-party services (e.g., Facebook).
                </p>
                <p className="pt-2 mb">
                  Permission Prerequisite: For any application exceeding
                  personal dissemination—like for business objectives or public
                  postings—users must secure explicit written consent from
                  PlotPilot Ltd. This pertains to postings on public forums,
                  blogs, or other similar platforms.User-Generated Content:
                </p>
                <p className="pt-2 mb">
                  By using PlotPilot, you may have the option to submit
                  user-generated prompts for the AI to process. You understand
                  and acknowledge that any content you submit will be processed
                  and stored by PlotPilot Ltd. for the sole purpose of providing
                  you with the AI-generated narrations.
                </p>
                <h6 className="pt-2 mb">4: Privacy and Data Usage</h6>
                <p className="pt-2 mb">
                  Your privacy is important to us. Our Privacy Policy outlines
                  how we collect, use, and protect your personal information and
                  user-generated prompts. By using PlotPilot, you consent to the
                  collection and use of your data as described in the Privacy
                  Policy.
                </p>
                <h6 className="pt-2 mb">5: Prohibited Conduct</h6>
                <p className="pt-2 mb">
                  While using PlotPilot, you agree not to engage in the
                  following activities:
                </p>
                <h6 className="pt-2 mb">
                  Parties with whom you may choose to share your User Content:
                </h6>
                <p className="pt-2 mb">
                  Violating any applicable laws, regulations, or third-party
                  rights.
                </p>
                <h6 className="pt-2 mb">
                  Parties with whom you may choose to share your User Content:
                </h6>
                <p className="pt-2 mb">How We Use Information We Collect:</p>
                <p className="pt-2 mb">
                  Uploading, transmitting, or distributing any harmful,
                  offensive, or inappropriate content.
                </p>
                <h6 className="pt-2 mb">6: Disclaimer of Warranties</h6>
                <p className="pt-2 mb">
                  PlotPilot is provided on an "as is" and "as available" basis.
                  PlotPilot Ltd. makes no warranties or representations of any
                  kind, whether express or implied, regarding the accuracy,
                  reliability, suitability, or availability of the app or its
                  content. Your use of the app is at your own risk.
                </p>
                <h6 className="pt-2 mb">7: Limitation of Liability</h6>
                <p className="pt-2 mb">
                  PlotPilot Ltd. and its affiliates will not be liable for any
                  direct, indirect, incidental, special, or consequential
                  damages arising out of or in connection with your use or
                  inability to use PlotPilot, including any AI-generated
                  content.
                </p>
                <h6 className="pt-2 mb">8: Indemnification</h6>
                <p className="pt-2 mb">
                  You agree to indemnify and hold PlotPilot Ltd., its officers,
                  directors, employees, and agents harmless from any claim,
                  demand, or damage, including reasonable attorney's fees,
                  arising out of your breach of these terms or your violation of
                  any law or the rights of a third party.
                </p>
                <h6 className="pt-2 mb">9: Modification and Termination</h6>
                <p className="pt-2 mb">
                  PlotPilot Ltd. reserves the right to modify, suspend, or
                  terminate the app or any part of it at any time, with or
                  without notice. We may also update these terms periodically,
                  and your continued use of the app after any changes will
                  signify your acceptance of the updated terms.
                </p>
                <h6 className="pt-2 mb">
                  10: Governing Law and Dispute Resolution
                </h6>
                <p className="pt-2 mb">
                  These terms shall be governed by and construed in accordance
                  with the laws of The United Kingdom. Any disputes arising
                  under or in connection with these terms shall be subject to
                  the exclusive jurisdiction of the courts in The United
                  Kingdom.
                </p>
                <h6 className="pt-2 mb">11: Ownership of Content</h6>
                <p className="pt-2 mb">
                  All content generated through the PlotPilot app, including but
                  not limited to AI-generated narrations and user-generated
                  prompts, is the sole and exclusive property of PlotPilot Ltd.
                  The app's content is protected by copyright, trademark, and
                  other intellectual property laws.
                </p>
                <h6 className="pt-2 mb">12: License to Users</h6>
                <p className="pt-2 mb">
                  By using the PlotPilot app, users are granted a limited,
                  non-exclusive, and non-transferable license to access and use
                  the AI-generated narrations for personal, non-commercial
                  purposes. This license does not grant users ownership rights
                  or any rights to distribute, sell, lease, sublicense, or
                  exploit the content commercially laws.
                </p>
                <h6 className="pt-2 mb">User-Generated Content License:</h6>
                <p className="pt-2 mb">
                  Users who submit prompts or content to the app hereby grant
                  PlotPilot Ltd. a perpetual, irrevocable, royalty-free,
                  worldwide, and non-exclusive license to use, reproduce,
                  modify, adapt, publish, translate, create derivative works
                  from, distribute, and display such user-generated content in
                  connection with the app's services and promotional activities.
                </p>
                <h6 className="pt-2 mb">Purchasing terms</h6>
                <p className="pt-2 mb">PlotPilot Subscriptions:</p>
                <p className="pt-2 mb">PlotPilot Pro (Annual)</p>
                <p className="pt-2 mb">PlotPilot Pro (Monthly)</p>
                <p className="pt-2 mb">PlotPilot Pro (Weekly)</p>
              </div>
              <h6 className="pt-2 mb">Audio Story Duration:</h6>
              <p className="pt-2 mb">
                Each generated audio book or story is approximately 5 minutes in
                length. However, the duration may vary based on the user's
                prompt and input.
              </p>
              <h6 className="pt-2 mb">Duration of Subscription:</h6>
              <p className="pt-2 mb">
                All subscriptions options are renewable subscriptions, and will
                auto-renew unless opted-out. PlotPilot Weekly renews every week,
                with the monthly subscription will be valid for 30 days from the
                date of purchase, while the yearly subscription covers a period
                of 12 consecutive months.
              </p>
              <h6 className="pt-2 mb">Payment Procedure:</h6>
              <p className="pt-2 mb">
                Payments for the subscriptions must be made via the app's
                designated payment gateways. All fees pertaining to the chosen
                subscription will be transparently presented during the
                transaction.
              </p>
              <h6 className="pt-2 mb">Non-Transferable:</h6>
              <p className="pt-2 mb">
                Subscriptions are unique to the purchaser and cannot be
                transferred, sold, or gifted to other users or third parties.
              </p>
              <h6 className="pt-2 mb">Expiration:</h6>
              <p className="pt-2 mb">
                Subscriptions automatically expire at the end of the chosen
                duration, either monthly or yearly. Renewal is necessary for
                continued service.
              </p>
              <h6 className="pt-2 mb">Usage:</h6>
              <p className="pt-2 mb">
                Once subscribed, users can generate audio books without worrying
                about hourly limits. The services remain available until the end
                of the subscription period.
              </p>
              <h6 className="pt-2 mb">Refund Policy:</h6>
              <p className="pt-2 mb">
                All refunds for subscriptions will be governed by PlotPilot
                Ltd.'s official refund policy. It is recommended that users
                familiarize themselves with this policy before purchasing
              </p>
              <h6 className="pt-2 mb">Changes to Subscription Plans:</h6>
              <p className="pt-2 mb">
                PlotPilot Ltd. holds the authority to alter or terminate
                subscription plans, including their pricing, at any time. Prior
                notice will be given to users regarding any such changes.
              </p>
              <h6 className="pt-2 mb">Special Offers:</h6>
              <p className="pt-2 mb">
                Occasionally, PlotPilot Ltd. might introduce promotions or
                discounts on subscriptions. All specific terms and conditions
                pertaining to these promotions will be announced during the
                promotional time.
              </p>
              <h6 className="pt-2 mb">Changes to Subscription Plans:</h6>
              <p className="pt-2 mb">
                PlotPilot Ltd. holds the authority to alter or terminate
                subscription plans, including their pricing, at any time. Prior
                notice will be given to users regarding any such changes.
              </p>
              <h6 className="pt-2 mb">Legal Adherence:</h6>
              <p className="pt-2 mb">
                All users must act in accordance with the laws and regulations
                of the United Kingdom when buying a subscription for audio book
                generation.
              </p>
              <p className="pt-2 mb">
                General Refund Criteria: Users who have subscribed to the
                PlotPilot app's audio book generation service are typically not
                entitled to refunds. However, specific conditions may allow for
                refund eligibility as detailed below:
              </p>
              <p className="pt-2 mb">
                App Un-usability: The only circumstance under which users may be
                eligible for a refund is if the PlotPilot app becomes entirely
                non-functional for the full duration of the user's subscription
                period.
              </p>
              <h6 className="pt-2 mb">No Refund for Non-Usage:</h6>
              <p className="pt-2 mb">
                For refunds, you'll need to reach out directly to Apple Support
                via getsupport.apple.com. As app developers, we don't have the
                capability to manage user subscriptions directly. We apologize
                for any inconvenience this may cause.
              </p>
              <p className="pt-2 mb">
                When requesting a refund, it's beneficial to provide a detailed
                explanation for the cancellation. Apple Support will assess your
                situation and update you on the outcome
              </p>
              <h6 className="pt-2 mb">Processing of Refunds:</h6>
              <p className="pt-2 mb">
                All refund applications will undergo review by Apple Inc. Once
                approved, the refunds will be processed in a reasonable time
                frame.
              </p>
              <h6 className="pt-2 mb">Refund Amount for Promotions:</h6>
              <p className="pt-2 mb">
                If users had initially subscribed under a promotional or
                discounted rate, the refund would be adjusted accordingly,
                taking into account the promotional value.
              </p>
              <h6 className="pt-2 mb">Expiry and Refund Ineligibility:</h6>
              <p className="pt-2 mb">
                If a user's subscription period expires without any claim for
                unusability, no refund will be applicable post-expiration.
              </p>
              <h6 className="pt-2 mb">PlotPilot Ltd.'s Discretion:</h6>
              <p className="pt-2 mb">
                Apple Inc. retains the right to evaluate each refund request
                individually. Decisions to grant or decline refunds lie solely
                with Apple Inc., based on the specifics of each case.
              </p>
              <h6 className="pt-2 mb">Refund Mechanism:</h6>
              <p className="pt-2 mb">
                All affirmed refunds will be returned using the initial payment
                method opted for during subscription.
              </p>
              <h6 className="pt-2 mb">Policy Alterations:</h6>
              <p className="pt-2 mb">
                The refund policy is subject to changes at the sole discretion
                of PlotPilot Ltd. Users will be informed of any modifications.
              </p>
              <p className="pt-2 mb">
                By subscribing to the audio book generation service on
                PlotPilot, users confirm their understanding of and compliance
                with this refund policy. Users are encouraged to familiarize
                themselves with this policy prior to any purchase.
              </p>
              <h6 className="pt-2 mb">Limitations on Content</h6>
              <p className="pt-2 mb">1: Prohibited Content:</p>
              <p className="pt-2 mb">
                The following types of content or prompts are strictly
                prohibited within the PlotPilot app. Users are not allowed to
                submit or engage in any activities related to such content:
              </p>
              <p className="pt-2 mb">
                Content that violates any applicable laws, regulations, or
                third-party rights.
              </p>
              <p className="pt-2 mb">
                Content that is defamatory, abusive, harassing, threatening, or
                harmful to any individual or group
              </p>
              <p className="pt-2 mb">
                Content that promotes or glorifies violence, self-harm, suicide,
                or any illegal activities.
              </p>
              <p className="pt-2 mb">
                Content that contains hate speech, discriminatory remarks, or
                promotes intolerance based on race, ethnicity, religion, gender,
                or any other personal attributes.
              </p>
              <p className="pt-2 mb">
                Sexually explicit or pornographic content.
              </p>
              <p className="pt-2 mb">
                Content that infringes upon the intellectual property rights of
                any third party.
              </p>
              <p className="pt-2 mb">
                Misleading, fraudulent, or deceptive content, including false or
                misleading claims about the app.
              </p>
              <p className="pt-2 mb">
                Content that contains viruses, malware, or any other harmful
                elements that could disrupt the app's functionality or
                compromise user data.
              </p>
              <p className="pt-2 mb">
                Content that violates the privacy of others, including the
                unauthorized sharing of personal information or private
                communications.
              </p>
              <h6 className="pt-2 mb">2: Moderation and Removal:</h6>
              <p className="pt-2 mb">
                PlotPilot Ltd. reserves the right to review, monitor, and
                moderate all content submitted by users. If any content is found
                to violate the above limitations or any other part of the app's
                terms and conditions, PlotPilot Ltd. may take appropriate
                action, including removing or blocking access to such content
                and suspending or terminating user accounts involved in posting
                or sharing prohibited content.
              </p>
              <h6 className="pt-2 mb">3: User Responsibility</h6>
              <p className="pt-2 mb">
                Users are solely responsible for the content they submit or
                share through the PlotPilot app. By using the app, users affirm
                that they will not engage in any activities that violate the
                app's content limitations.
              </p>
              <h6 className="pt-2 mb">4: Report Inappropriate Content:</h6>
              <p className="pt-2 mb">
                Users are encouraged to report any content they come across
                within the app that they believe may be inappropriate or in
                violation of the content limitations. Reports can be submitted
                through the designated reporting mechanism provided within the
                app.
              </p>
              <h6 className="pt-2 mb">
                5: No Liability for User-Generated Content:
              </h6>
              <p className="pt-2 mb">
                PlotPilot Ltd. disclaims any liability or responsibility for the
                user-generated content submitted through the app. Users
                acknowledge that they use the app at their own risk and are
                solely responsible for any consequences resulting from their
                content submissions.
              </p>
              <p className="pt-2 mb">
                By using the PlotPilot app, users agree to comply with the above
                content limitations and understand that any violation may result
                in appropriate actions taken by PlotPilot Ltd., including
                content removal, account suspension, or termination. PlotPilot
                Ltd. reserves the right to update or modify the content
                limitations as necessary to ensure a safe and appropriate
                environment for all users.
              </p>
              <h6 className="pt-2 mb">Changing Subscription Preferences</h6>
              <p className="pt-2 mb">1. Subscription Plans</p>
              <p className="pt-2 mb">
                1.1 Plan Options: PlotPilot offers Weekly, Monthly, and Yearly
                subscription plans, granting user access to 'pro' features
                within the app and credits used to generate AI audiobooks.
              </p>
              <p className="pt-2 mb">
                1.2 Billing: Billing is conducted in advance and is based on the
                cycle users select—weekly, monthly, or yearly.
              </p>
              <p className="pt-2 mb">
                1.3 Plan Selection: users can choose their preferred
                subscription plan within the app. All associated fees will be
                transparently displayed before purchase.
              </p>
              <p className="pt-2 mb">
                2.1 Flexibility: users have the option to switch between
                subscription plans at any time.
              </p>
              <p className="pt-2 mb">
                2.2 Immediate Effect: Changes a users subscription plan will be
                effective at the end of their current subscription plan period,
                and all associated credits and benefits will transfer at that
                time.
              </p>
              <p className="pt-2 mb">
                2.3 Example: Upgrading: If users upgrade from a Weekly to a
                Monthly plan, the new plan and corresponding billing will begin
                when the weekly plan period ends.
              </p>
              <p className="pt-2 mb">
                2.4 Example: Downgrading: Should users downgrade, their new
                billing rate will commence at the end of their current
                subscription period.
              </p>
              <h6 className="pt-2 mb">3. Cancellation and Refunds</h6>
              <p className="pt-2 mb">
                To cancel an ongoing subscription, please refer to the
                guidelines on Apple’s official support page:
                support.apple.com/en-us/HT202039
              </p>
              <p className="pt-2 mb">
                . For additional assistance, contact us at:
                accounts@plotpilot.ai
              </p>
              <h6 className="pt-2 mb">4. Modifications Prior to Purchase</h6>
              <p className="pt-2 mb">4.1 Notification:</p>
              <p className="pt-2 mb">
                PlotPilot will inform users of any changes to subscription plans
                or terms via the app or their registered contact information.
              </p>
              <p className="pt-2 mb">
                By proceeding with your subscription, you confirm that you have
                read, understood, and agree to these terms.
              </p>
              <p className="pt-2 mb">
                Terms and conditions apply from PlotPilot's App version;
              </p>
              <p className="pt-2 mb">v1.0.0 - (current)</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
