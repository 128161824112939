import { Helmet } from "react-helmet";
import PageBanner from "../components/Common/PageBanner";
import ContentOne from "./../components/AboutPage/ContentOne";

export default function About() {
  return (
    <>
      <Helmet>
        <title>PlotPilot - Terms & Conditions</title>
      </Helmet>
      <PageBanner
        title="Terms & Conditions"
        content="Last updated: September 6, 2023"
      />
      <ContentOne />
    </>
  );
}
