import { FaTwitter, FaTiktok, FaDiscord, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import brandLogo from "assets/img/footer-logo.svg";
export default function Footer() {
  return (
    <footer className="footer-wrapper">
      <div className="footer-widgets-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
              <div className="single-footer-widget wow fadeInLeft">
                <div className="about-us-widget">
                  <Link to="/" className="footer-logo d-block">
                    <img src={brandLogo} alt="xmoze" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 offset-xl-1 col-md-6 col-12">
              <div
                className="single-footer-widget wow fadeInLeft"
                data-wow-delay=".2s"
              >
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 offset-xl-1 col-md-6 col-12">
              <div
                className="single-footer-widget wow fadeInLeft"
                data-wow-delay=".4s"
              >
                <li>
                  <Link to="/terms">Terms & Conditions</Link>
                </li>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 offset-xl-1 col-md-6 col-12">
              <div
                className="single-footer-widget wow fadeInLeft"
                data-wow-delay=".6s"
              >
                <li>
                  <Link to="/contact-us">Contact Support</Link>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-wrapper">
        <div className="container">
          <div className="footer-bottom-content d-md-flex justify-content-between">
            <div
              className="site-copyright wow fadeInUp"
              data-wow-delay=".2"
              data-wow-duration="1s"
            >
              <p>
                &copy; 2024
                <Link to="/"> PlotPilot, Ltd</Link>
              </p>
            </div>
            <div
              className="social-links mt-4 mt-md-0 wow fadeInUp"
              data-wow-delay=".3"
              data-wow-duration="1s"
            >
              <a
                href="https://twitter.com/PlotPilotHQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
              <a
                href="https://tiktok.com/@plotpilotpro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTiktok />
              </a>
              <a
                href="https://discord.gg/eVKTS8wmxg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaDiscord />
              </a>
              <a
                href="https://instagram.com/plotpilothq"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
