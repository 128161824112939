import { Helmet } from "react-helmet";
import PageBanner from "../components/Common/PageBanner";
import HeroThree from "components/Hero/Three";

export default function About() {
  return (
    <>
      <Helmet>
        <title>PlotPilot - About</title>
      </Helmet>
      <PageBanner
        title="About"
        content="Unleash Your Creativity: Craft Characters, Weave Interactive Tales, and Share Your Stories with the World."
      />
      <HeroThree />
    </>
  );
}
