import heroImg from "assets/img/home3/hero-mobile.png";
import heroUserImg from "assets/img/user/1.png";
import heroUserImg2 from "assets/img/user/2.png";
import heroUserImg3 from "assets/img/user/3.png";
import heroUserImg4 from "assets/img/user/4.png";
import heroUserImg5 from "assets/img/user/5.png";
import heroArrowIcon from "assets/img/home3/hero-arrow.png";
import android from "assets/img/android.png";
import apple from "assets/img/apple.png";
export default function HeroThree() {
  return (
    <section className="hero-welcome-wrapper hero-3 fw500">
      <div className="single-slide">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-12 text-xl-start mt-5 mt-xl-0 text-center order-2 order-xl-1">
              <div className="hero-mobile">
                <img src={heroImg} alt="hero banner" />
              </div>
            </div>
            <div className="col-xl-7 ps-xl-5 text-center text-xl-start col-12 order-1 order-xl-2">
              <div className="hero-contents">
                <h1>Start your interactive AI journey today!</h1>
                <p>
                  PlotPilot revolutionizes storytelling by empowering you to
                  create and share immersive, interactive tales with characters
                  of your own design, all enhanced by AI for visuals and audio,
                  in a vibrant community of creators. It's where your
                  imagination comes alive.
                </p>
                <a
                  href="https://apps.apple.com/us/app/plotpilot-ai-powered-stories/id6463313187"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-download-btn"
                >
                  <img src={apple} alt="apple" />
                </a>
                <div className="client-feedback-wrapper">
                  <div className="hero-arrow">
                    <img src={heroArrowIcon} alt="arrow icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
