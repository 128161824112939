import ContentImage from "assets/img/home1/block-img1.png";
export default function ContentBlockOne() {
  return (
    <section className="content-block section-padding section-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 pe-lg-0 col-lg-5 col-12">
            <div className="block-img wow fadeInLeft" data-wow-duration="1.1s">
              <img src={ContentImage} alt="content-image" />
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 offset-xl-1 col-12 ps-lg-5 pe-xl-5">
            <div className="block-contents ms-xl-3 mt-5 mt-lg-0">
              <div
                className="section-title wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <h2>Interact with the storyline</h2>
              </div>
              <p
                className="wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                Launch into storytelling where you're the master of fate. Mix in
                your characters, set the scene with vivid visuals, and steer the
                plot with your choices as you interact with the storyline.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
