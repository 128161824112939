import React from "react";
import interfaceImage from "assets/img/home2/interface.png";
import interfaceChart from "assets/img/home2/interface-chart.png";
export default function Interfaces() {
  return (
    <section className="content-block section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-12 pe-xl-5 mt-5 mt-xl-0 order-2 order-xl-1">
            <div className="block-contents">
              <div
                className="section-title mb-4 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".1s"
              >
                <h2>Support</h2>
              </div>
              <p
                className="wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                Your email isn't going to the inbox abyss, never to be seen or
                heard from again. At PlotPilot, we provide the exceptional
                service we'd want to experience ourselves!
              </p>
              <a
                href="mailto:accounts@plotpilot.ai"
                className="theme-btn second-color mt-10 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="1.1s"
              >
                accounts@plotpilot.ai
              </a>
            </div>
          </div>

          <div className="col-xl-6 pe-lg-0 col-12 order-1 order-xl-2">
            <div className="block-img-right">
              <img src={interfaceImage} alt="interface" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
