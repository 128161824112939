import React from "react";
import apple from "assets/img/apple.png";
import tirShape from "assets/img/icons/tir-shape.svg";
import mobile from "assets/img/mobile-hero1.png";
export default function Hero() {
  return (
    <section className="hero-welcome-wrapper hero-1">
      <div className="single-slide text-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-12 col-lg-10 offset-lg-1 offset-xl-0">
              <div className="hero-contents">
                <h1>Make interactive stories with AI Characters</h1>
                <p>
                  Join a Unique AI Experience where you create interactive
                  stories with characters you make!
                </p>
                <a
                  href="https://apps.apple.com/us/app/plotpilot-ai-powered-stories/id6463313187"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-download-btn"
                >
                  <img src={apple} alt="apple" />
                </a>
                <div className="tri-arrow">
                  <img src={tirShape} alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-12 text-xl-end col-lg-10 offset-lg-1 offset-xl-0">
              <div className="hero-mobile mt-4 mt-xl-0">
                <img src={mobile} alt="xmoze app" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
