import { useState } from "react";
import { FaBars, FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import MenuItems from "./Menu/MenuItems";
import { menuItems } from "./menuItems";
import BrandLogo from "../../assets/img/logo.svg";
import apple from "assets/img/apple.png";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="header-1">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-2 col-sm-5 col-md-4 col-6">
            <div className="logo">
              <Link to="/">
                <img src={BrandLogo} alt="Transland" />
              </Link>
            </div>
          </div>
          <div className="col-lg-10 text-end p-lg-0 d-none d-lg-flex justify-content-between align-items-center">
            <div className="menu-wrap">
              <div className="main-menu">
                <ul>
                  <li>
                    <a href="#">Company</a>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Support</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms">Terms & Conditions</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Community</a>
                    <ul className="sub-menu">
                      <li>
                        <a
                          href="https://twitter.com/PlotPilotHQ"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          X
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tiktok.com/@plotpilotpro"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          TikTok
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://discord.gg/eVKTS8wmxg"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Discord
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://instagram.com/plotpilothq"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Instagram
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-right-element">
              <a
                href="https://apps.apple.com/us/app/plotpilot-ai-powered-stories/id6463313187"
                target="_blank"
                rel="noopener noreferrer"
                className="theme-btn"
              >
                Try PlotPilot
              </a>
            </div>
          </div>
          <div className="d-block d-lg-none col-sm-1 col-md-8 col-6">
            <div className="mobile-nav-wrap">
              <div id="hamburger" onClick={() => setIsOpen((prev) => !prev)}>
                <FaBars />
              </div>

              <div className={`mobile-nav ${isOpen ? "show" : ""}`}>
                <button
                  type="button"
                  className="close-nav"
                  onClick={() => setIsOpen((prev) => !prev)}
                >
                  <FaTimesCircle />
                </button>
                <nav className="sidebar-nav">
                  <ul className="metismenu" id="mobile-menu">
                    {menuItems.map((menu, index) => (
                      <MenuItems
                        isOpenMenu={isOpen}
                        key={index}
                        items={menu}
                        closeMenu={() => setIsOpen(false)}
                      />
                    ))}
                  </ul>

                  <a
                    href="https://apps.apple.com/us/app/plotpilot-ai-powered-stories/id6463313187"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="app-download-btn"
                  >
                    <img src={apple} alt="apple" />
                  </a>
                </nav>
              </div>
            </div>
            <div
              className={`overlay ${isOpen ? "active" : ""}`}
              onClick={() => setIsOpen((prev) => !prev)}
            ></div>
          </div>
        </div>
      </div>
    </header>
  );
}
