import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";

import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

export default function MenuItems({ items, isOpenMenu, closeMenu }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (!isOpenMenu) {
      setIsDropdownOpen(false);
    }
  }, [isOpenMenu]);

  const handleLinkClick = () => {
    // Close the menu
    closeMenu();
  };

  return (
    <li>
      {items.submenu ? (
        <>
          <a
            className="dropdown-button"
            onClick={() => setIsDropdownOpen((prev) => !prev)}
          >
            {items.title}
            <span className="dropdown-arrow">
              {isDropdownOpen ? <IoIosArrowDown /> : <IoIosArrowBack />}
            </span>
          </a>
          <Dropdown submenus={items.submenu} isDropdownOpen={isDropdownOpen} />
        </>
      ) : (
        <Link
          to={`${items.path !== "/" ? "/" + items.path : items.path}`}
          onClick={handleLinkClick}
        >
          {items.title}
        </Link>
      )}
    </li>
  );
}
