import { Helmet } from "react-helmet";
import PageBanner from "../components/Common/PageBanner";
import ContentTwo from "./../components/AboutPage/ContentTwo";

export default function About() {
  return (
    <>
      <Helmet>
        <title>PlotPilot - Privacy Policy</title>
      </Helmet>
      <PageBanner
        title="Privacy Policy"
        content="Last updated: September 6, 2023"
      />
      <ContentTwo />
    </>
  );
}
