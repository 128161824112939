import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./assets/styles/animate.css";
import "./assets/styles/bootstrap.min.css"; // Ensure the path starts with "./" for consistency
import "./assets/fonts/icons.css";
import "./assets/fonts/typography/fonts.css";
import "./assets/fonts/xmoze/xmoze.css";
import "./assets/styles/scss/style.scss";

ReactDOM.render(
  <BrowserRouter>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
