import { Helmet } from "react-helmet";
import CallToAction from "../components/DefaultHome/CallToAction";
import ContentBlockOne from "../components/DefaultHome/ContentBlockOne";
import ContentBlockTwo from "../components/DefaultHome/ContentBlockTwo";
import Hero from "../components/Hero/One";
import Pagewrapper from "../components/PageWrapper";

export default function Home() {
  return (
    <Pagewrapper>
      <Helmet>
        <meta
          name="google-site-verification"
          content="QicPBCOSVJtUyq6UTJ1-FQoKo3QjcV6izZ5c0cG7BVk"
        />
      </Helmet>
      <Hero />
      <ContentBlockOne />
      <ContentBlockTwo />
      <CallToAction />
    </Pagewrapper>
  );
}
