import { Helmet } from "react-helmet";
import PageBanner from "./../components/Common/PageBanner";
import CallToAction from "../components/Common/CallToAction";
import Interfaces from "../components/HomeTwo/Interfaces";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>PlotPilot - Support</title>
      </Helmet>
      <PageBanner
        title="Contact Us"
        content="We are here to help you with any questions you may have."
      />
      <Interfaces />
    </>
  );
}
