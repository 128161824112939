export default function ContentTwo() {
  return (
    <section className="promo-content-block fix section-padding section-bg">
      <div className="container">
        <div className="align-items-center">
          <div className="col-xl-12 col-12 mt-5 mt-xl-0">
            <div className="legal-contents">
              <div className="section-title mb-4">
                <h6 className="pt-2 mb">Privacy Policy</h6>
                <p className="pt-2 mb">
                  This Privacy Policy applies to Plotpilot App ("us," "we," or
                  "our"), owned and operated by PLOTPILOT Ltd, and is
                  incorporated into and subject to our Terms and Conditions,
                  which can be found at www.plotpilot.ai/terms
                </p>
                <p className="pt-2 mb">
                  In this Privacy Policy, we refer to our products and services
                  as the "Service." By using the Service, you signify your
                  agreement to the terms of this Privacy Policy. If you do not
                  agree with the terms of this Privacy Policy, please do not use
                  the Service.
                </p>
                <h6 className="pt-2 mb">Information We Collect</h6>
                <p className="pt-2 mb">
                  By accessing PlotPilot, you acknowledge that you have read,
                  understood, and agreed to these terms and conditions. You
                  further affirm that you are at least 12 years old or have
                  legal consent from a parent or guardian.
                </p>
                <h6 className="pt-2 mb">2: License</h6>
                <p className="pt-2 mb">
                  PlotPilot grants you a limited, non-exclusive,
                  non-transferable, and revocable license to access and use the
                  app for personal, non-commercial purposes only. You agree not
                  to copy, modify, distribute, sell, lease, sublicense, reverse
                  engineer, or decompile any part of the app.
                </p>
                <h6 className="pt-2 mb">3: AI-Powered Narration</h6>
                <p className="pt-2 mb">
                  When you install the Service on your device and register,
                  personally identifiable information about you may be collected
                  during the download process and when you sign up with us. For
                  registration, you are required to provide your email address,
                  city location, gender, and name, depending on the device(s)
                  you are using on the Service. Optionally, you may provide
                  additional personally identifiable information. When you log
                  in using your Facebook account, we may collect additional
                  information made available to us (see "Your User Profile"
                  below).
                </p>
                <p className="pt-2 mb">
                  In addition, when you install the Service on your device and
                  register, you may be asked to allow us access to your address
                  book. If you consent, we will have access to contact
                  information in your address book on the device you use for the
                  Service (names, numbers, emails, and Facebook IDs, but not
                  notes or other personal information) to help you use the
                  Service, such as synchronizing your contacts between different
                  devices.
                </p>
                <p className="pt-2 mb">
                  We may also collect non-personally identifiable information,
                  such as profile information, country of residence, and
                  preferences. Additionally, we collect and store information
                  about your and others’ usage of and interaction with the
                  Service, including matches, match durations, text messages,
                  usage by geographies, device and connection information, IP
                  address, device capability, bandwidth, statistics on page
                  views, network type, and traffic to and from our websites.
                </p>
                <p className="pt-2 mb">
                  Our app allows you to share text messages, photos,
                  screenshots, videos, and other communications with other
                  users, and if you choose to do so, this content will be stored
                  on our servers. If you share these items with other users, we
                  may not be able to remove them from our servers or make them
                  unavailable to anyone you have shared them with. Sending and
                  sharing content in the app is your decision, and you should
                  understand that it may become publicly available, depending on
                  your actions or the actions of others with whom you have
                  shared the information. We are not responsible for any use or
                  misuse of information you share. Additionally, in case your
                  conversation partner reports your abusive behavior or
                  language, conversation information stored only on your
                  partner's device may be transferred to our servers to
                  investigate the report and determine appropriate actions, if
                  needed.
                </p>
                <p className="pt-2 mb">
                  We may also collect user-generated content (e.g., photos,
                  screenshots, comments, and other materials) that you create on
                  the Service. Your photos can be taken by other users on our
                  services, and if they use the capturing function provided by
                  us, those photos can be stored and used for our services and
                  third-party services (e.g., Facebook).
                </p>
                <h6 className="pt-2 mb">4: Privacy and Data Usage</h6>
                <p className="pt-2 mb">
                  Your privacy is important to us. Our Privacy Policy outlines
                  how we collect, use, and protect your personal information and
                  user-generated prompts. By using PlotPilot, you consent to the
                  collection and use of your data as described in the Privacy
                  Policy.
                </p>
                <p className="pt-2 mb">
                  We also collect error-reporting information if the Service
                  crashes or hangs up, to investigate errors and improve the
                  stability of the Service for future releases. These reports do
                  not contain personally identifiable information, except
                  incidentally. The information may include the type and version
                  of your device, device identifier, time of the error, the
                  feature being used, and the state of the application when the
                  error occurred. We only use this information for investigating
                  and fixing errors.
                </p>
                <h6 className="pt-2 mb">Sharing of Your Information</h6>
                <p className="pt-2 mb">
                  We will not rent or sell your information to third parties
                  without your consent, except as noted in this Privacy Policy.
                </p>
                <h6 className="pt-2 mb">
                  Parties with whom we may share your information:
                </h6>
                <p className="pt-2 mb">
                  We may share User Content and your information with businesses
                  that are legally part of the same group of companies as us
                  ("Affiliates"). Affiliates may use this information to
                  provide, understand, and improve the Service and their own
                  services. We may share your information with third-party
                  organizations that help us provide the Service to you
                  ("Service Providers"). Our Service Providers will access your
                  information as necessary to provide the Service under
                  reasonable confidentiality terms. We may share aggregate or
                  anonymous information with third parties, including
                  advertisers and investors, to develop content and services. We
                  may remove parts of data that can identify you and share
                  anonymized data with other parties. We may also combine your
                  information with other data in a way that is no longer
                  associated with you and share that aggregated information.
                </p>
                <h6 className="pt-2 mb">
                  Parties with whom you may choose to share your User Content:
                </h6>
                <p className="pt-2 mb">
                  Any information or content that you voluntarily disclose for
                  posting to the Service, such as User Content, becomes
                  available to the public. Once you have shared User Content or
                  made it public, others may re-share it. If you remove
                  information and contents (e.g., screenshots, etc.) that you
                  created on the Service, copies may remain viewable in cached
                  and archived pages of the Service or if other Users or third
                  parties have copied or saved that information (e.g., Facebook,
                  etc.).
                </p>
                <h6 className="pt-2 mb">
                  Parties with whom you may choose to share your User Content:
                </h6>
                <p className="pt-2 mb">How We Use Information We Collect:</p>
              </div>
              <p className="pt-2 mb">
                We use or may disclose your personal information only as
                follows.
              </p>
              <p className="pt-2 mb">
                - To share your Profile with others on the Service;
              </p>
              <p className="pt-2 mb">
                - To connect you with others as enabled by the Service;
              </p>
              <p className="pt-2 mb">
                - To allow your use of certain features of the Service, such as
                Premium Services;
              </p>
              <p className="pt-2 mb">
                - To deliver administrative notices, alerts, and communications
                relevant to your use of the Service;
              </p>
              <p className="pt-2 mb">
                - To provide you with relevant content that you requested using
                information that you allow us to collect or provide to a social
                media provider connected to your account;
              </p>
              <p className="pt-2 mb">
                - To contact you via email, SMS, or otherwise for the purpose of
                informing you about new products, services, or promotions
                offered by us (you can opt-out of such emails or SMS);
              </p>
              <p className="pt-2 mb">
                - For internal operations, troubleshooting problems, data
                analysis, testing, research, improvements to the Service,
                detecting and protecting against error, fraud, or other illegal
                activity;
              </p>
              <p className="pt-2 mb">
                - When required by law or legal process, law enforcement,
                national security, or an issue of public importance;
              </p>
              <p className="pt-2 mb">
                - To protect and defend our rights or property, including
                enforcing our Terms of Use and other agreements;
              </p>
              <p className="pt-2 mb">
                - In connection with a corporate transaction involving us, such
                as the purchase or sale of a business unit, an acquisition,
                merger, sale of assets, or other similar event.
              </p>
              <h6 className="pt-2 mb">Your User Profile</h6>
              <p className="pt-2 mb">
                The information you enter into your user profile (your
                "Profile") may be shared with your contacts. You control your
                Profile and can access and modify it from the application at any
                time. If you register using your Facebook account, we may store
                your Facebook ID and corresponding token on our servers, and
                with your express consent, collect certain information made
                available on Facebook (e.g., your first and last names, email
                address, profile picture, your friend lists, and birthdays).
                Your Profile is available to other users of the Service who are
                connected to you. Additionally, your Profile may be discoverable
                by other users in listings of contacts, including proximity to
                other users. However, you have the option to "block out" any
                user from discovering your Profile at any time. We may
                pre-populate your Profile with certain information, such as your
                name and Facebook profile picture, if you or your friends have
                logged onto the Service using Facebook.
              </p>
              <h6 className="pt-2 mb">Data Access and Removal</h6>
              <p className="pt-2 mb">
                You have control over what information you choose to share with
                us on the Service. To do so, you can change your settings in the
                Service or your mobile device. Additionally, you have the right
                to remove the Service from your mobile device at any time. You
                can also request the deletion of your account, and we will make
                commercially reasonable efforts to remove your data from our
                servers. However, please note that any personally identifiable
                information shared in text messages, photos, videos, or other
                communications with other users may remain viewable and
                accessible to those users even after the removal of your
                account.
              </p>
              <h6 className="pt-2 mb">11: Ownership of Content</h6>
              <p className="pt-2 mb">
                All content generated through the PlotPilot app, including but
                not limited to AI-generated narrations and user-generated
                prompts, is the sole and exclusive property of PlotPilot Ltd.
                The app's content is protected by copyright, trademark, and
                other intellectual property laws.
              </p>
              <h6 className="pt-2 mb">Service Providers</h6>
              <p className="pt-2 mb">
                In certain instances, we may have contracts with third parties
                to provide products and/or services in connection with the
                Service. In those cases, we may share your personal information
                with our third-party service providers, but only to the extent
                necessary to provide the services to you. For example, we may
                share your phone number or email with third-party SMS and email
                providers to deliver authentication messages when you register.
                We require these third parties to use your information solely
                for delivering messages to you and to maintain appropriate
                security measures to protect your information. Please note that
                this Privacy Policy does not apply to the practices of
                third-party service providers.
              </p>
              <h6 className="pt-2 mb">Outbound Links</h6>
              <p className="pt-2 mb">
                If you access a website, product, or service provided by a third
                party, including through the Service, such third party may
                collect information about you. Please review the privacy
                policies of each such third party for more information about how
                they use the information they collect. This Privacy Policy does
                not apply to any exchange of information between you and any
                third party.
              </p>
              <h6 className="pt-2 mb">Opt-Out Promotional Communications</h6>
              <p className="pt-2 mb">
                You have the option to choose not to receive promotional email
                messages from us and our service providers. You may opt-out by
                following the instructions provided in the email message or
                contacting us at accounts@plotpilot.ai.
              </p>
              <p className="pt-2 mb">
                Privacy Policy applies from PlotPilot App Version:
              </p>
              <p className="pt-2 mb">v1.0.0 - (current) PlotPilot</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
