import React from "react";
import ctarightImage from "assets/img/cta-right-img.png";
import ctatirShape from "assets/img/icons/tir-shape.svg";
import android from "assets/img/android.png";
import apple from "assets/img/apple.png";
export default function CallToAction() {
  return (
    <section className="cta-banner-wrapper style-1 section-padding pt-0">
      <div className="container">
        <div className="cta-banner text-white">
          <div className="row">
            <div className="col-xxl-6 text-center text-xxl-start offset-xxl-1">
              <div className="cta-contents">
                <h2 className="wow fadeInUp">
                  Dive into the world of AI-Powered Stories
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".3s">
                  Unlimited stories, unlimited possibilities. Download the app
                  and start creating your own characters and stories!
                </p>
                <a
                  href="https://apps.apple.com/us/app/plotpilot-ai-powered-stories/id6463313187"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-download-btn"
                >
                  <img src={apple} alt="apple" />
                </a>
                <div
                  className="tri-arrow wow fadeInRight d-none d-md-inline-block"
                  data-wow-delay="1s"
                >
                  <img src={ctatirShape} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xxl-5 pe-xxl-5">
              <div
                className="cta-mobile-app wow fadeInUp"
                data-wow-delay=".4s"
                data-wow-duration="1.2"
              >
                <img src={ctarightImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
