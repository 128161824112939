export const menuItems = [
  {
    title: "About",
    path: "about",
  },
  {
    title: "support",
    path: "contact-us",
  },
  {
    title: "Privacy Policy",
    path: "privacy",
  },
  {
    title: "Terms & Conditions",
    path: "terms",
  },
];
