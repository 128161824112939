import StepAccordion from "../Accordion/StepAccordion";
import contentImage from "assets/img/home1/faq-img.png";
const data = [
  {
    id: 1,
    title: "Download the PlotPilot App!",
    body: "You can easily download the PlotPilot app from the App Store. Google Play is coming soon!",
  },
  {
    id: 2,
    title: "Create your own characters",
    body: "Dive into character creation and shape your characters just the way you imagine them.",
  },
  {
    id: 3,
    title: "Create interactive stories with your characters",
    body: "Embark on a story where your choices guide the fate of your characters",
  },
];
export default function ContentBlockTwo() {
  return (
    <section className="content-block fix faq-wrapper section-padding section-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-xl-5 mt-5 mt-lg-0 order-2 order-lg-1">
            <div className="block-contents">
              <div
                className="section-title wow fadeInUp"
                data-wow-duration="1s"
              >
                <h2>Create your own characters today!</h2>
              </div>
            </div>
            <div className="step-accordion">
              <StepAccordion content={data} />
            </div>
          </div>
          <div className="col-lg-6 col-xl-6 offset-xl-1 pe-xl-3 col-12 order-1 order-lg-2">
            <div
              className="block-img ms-xl-5 wow fadeInRight"
              data-wow-duration="1.2s"
              data-wow-delay=".3s"
            >
              <img src={contentImage} alt="faq" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
