import { Helmet } from "react-helmet";
export default function Pagewrapper({ title, children }) {
  return (
    <>
      <Helmet>
        <title>{title || "PlotPilot - AI-Powered Stories "}</title>
      </Helmet>
      {children}
    </>
  );
}
